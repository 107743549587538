
export default {
    async asyncData({ req, params, ssrContext }) {
        let host = null
        let userIdentifier = null
        let customPayload = null
        if (process.server) {
            host = req.headers.host
            userIdentifier = ssrContext.nuxt.userIdentifier
            customPayload = ssrContext.nuxt.customPayload
            return { host, userIdentifier, customPayload }
        } else {
            host = window.location.host
            userIdentifier = window.__NUXT__.userIdentifier
            customPayload = window.__NUXT__.customPayload
            console.log('userIdentifier', userIdentifier)
            console.log('host', host)
            return { host, userIdentifier, customPayload }
        }
    },
    data() {
        return {
            host: null
        }
    }
}
